<template>
  <div class="mt-3" v-if="isOwner" id="userCashierContainer">
    <CRow id="userCashierHeaderRow">
      <CCol md="12" class="text-center" id="userCashierHeaderCol">
      <div class="d-inline-flex align-items-start" id="userCashierHeaderTitle">
        <h2 class="font-weight-medium text-dark mb-0" id="userCashierTitle">
          {{ $t('cashierManagement') }}
        </h2>
        <HelpButton :isHeader="false" class="ml-2 mt-2" id="userCashierHelpButton"></HelpButton>
      </div>
        <h6 class="text-description" id="userCashierDescription">
          {{ $t('cashierCRUDDescription') }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    <ul class="list-group list-group-flush" id="userCashierList">
      <li
        class="list-group-item"
        v-for="(cashier, index) in data"
        :key="cashier.objectId"
        :id="`userCashierItem-${index}`"
      >
      <table style="width: 100%; table-layout: fixed;" :id="`userCashierTable-${index}`">
        <tr id="userCashierRow">
          <td style="width: 10%; text-align: left;" id="userCashierImageCol">
            <img
              class="img-fluid"
              src="/img/user.png"
              alt=""
              style="height: 50px; width: 50px;"
              id="userCashierImage"
            />
          </td>
          <td class="p-2" style="font-size: 15px; vertical-align: left;" id="userCashierNameCol">
            <span class="font-weight-medium" id="userCashierName">{{ cashier.username }}</span>
          </td>
          <td style="width:50%; text-align: right; vertical-align: right;" id="userCashierButtonCol">
            <button
              @click="manage(cashier)"
              class="btn btn-dark btn-sm"
              style="white-space: nowrap;"
              :id="`userCashierManageButton-${index}`"
            >
              {{ $t('cashierManageButton') }}
            </button>
          </td>
        </tr>
      </table>
      </li>
    </ul>
    <CModal id="userCashierModal" :show.sync="popupModal" :footer="footer" centered header="false">
      <div v-if="localUsername === ''" id="userCashierCreateForm">
        <h4 class="font-weight-medium" id="userCashierCreateTitle">
          {{ $t('cashierUsernamePassword') }}
        </h4>
        <small id="userCashierCreateDescription">{{ $t('cashierCRUDDescription') }}</small>
        <CAlert color="success" v-if="successAlert !== ''" id="userCashierSuccessAlert">
          {{ successAlert }}
        </CAlert>
        <CAlert color="danger" v-if="errorAlert !== ''" id="userCashierErrorAlert">
          {{ errorAlert }}
        </CAlert>
        <form class="mt-3" id="userCashierForm">
          <div class="form-group row" id="userCashierUsernameRow">
            <label class="col-sm-3 col-form-label font-weight-medium" id="userCashierUsernameLabel">
              {{ $t('username') }}
            </label>

            <div class="col-sm-9" id="userCashierUsernameInputCol">
              <input
                v-if="localUsername !== ''"
                type="text"
                :value="localUsername"
                class="form-control"
                disabled
                id="userCashierUsernameInputDisabled"
              />

              <input
                v-else
                type="text"
                v-model="user"
                class="form-control"
                :placeholder="$t('username')"
                id="userCashierUsernameInput"
              />
            </div>
          </div>
          <div class="form-group row" id="userCashierPasswordRow" >
            <label class="col-sm-3 col-form-label font-weight-medium" id="userCashierPasswordLabel" >
              {{ $t('password') }}
            </label>
            <div class="col-sm-9" id="userCashierPasswordInputCol">
              <input
                v-if="localUsername !== ''"
                type="password"
                class="form-control"
                placeholder="******"
                disabled
                id="userCashierPasswordInputDisabled"
              />
              <input
                v-else
                type="password"
                class="form-control"
                v-model="localpassword"
                :placeholder="$t('placeholderPassword')"
                id="userCashierPasswordInput"
              />
            </div>
          </div>
        </form>
      </div>
      <div v-else-if="localUsername !== ''" id="userCashierResetForm">
        <h4 class="font-weight-normal" id="userCashierResetTitle">
          {{ $t('resetPassword') }}
        </h4>
        <small id="userCashierResetDescription">{{ $t('cashierCRUDDescription') }}</small>
        <CAlert color="success" v-if="successAlert !== ''" id="userCashierResetSuccessAlert">
          {{ successAlert }}
        </CAlert>
        <CAlert color="danger" v-if="errorAlert !== ''" id="userCashierResetErrorAlert">
          {{ errorAlert }}
        </CAlert>
        <form class="mt-3" id="userCashierResetFormBody">
          <div class="form-group row" id="userCashierResetUsernameRow">
            <label class="col-sm-3 col-form-label font-weight-medium" id="userCashierResetUsernameLabel">
              {{ $t('username') }}
            </label>

            <div class="col-sm-9" id="userCashierResetUsernameInputCol">
              <input
                type="text"
                :value="localUsername"
                class="form-control"
                disabled
                id="userCashierResetUsernameInput"
              />
            </div>
          </div>
          <div class="form-group row" id="userCashierOldPasswordRow">
            <label class="col-sm-3 col-form-label font-weight-normal" id="userCashierOldPasswordLabel">
              {{ $t('oldPassword') }}
            </label>
            <div class="col-sm-9" id="userCashierOldPasswordInputCol">
              <input
                type="password"
                class="form-control"
                v-model="oldPassword"
                :placeholder="$t('oldPasswordPlaceholder')"
                id="userCashierOldPasswordInput"
              />
            </div>
          </div>
          <div class="form-group row" id="userCashierNewPasswordRow">
            <label class="col-sm-3 col-form-label font-weight-normal" id="userCashierNewPasswordLabel">
              {{ $t('newPassword') }}
            </label>
            <div class="col-sm-9" id="userCashierNewPasswordInputCol">
              <input
                type="password"
                v-model="localpassword"
                class="form-control"
                :placeholder="$t('placeholderPassword')"
                id="userCashierNewPasswordInput"
              />
            </div>
          </div>
        </form>
      </div>

      <template #footer>
        <div class="mx-auto" id="userCashierFooter">
          <div class="row" id="userCashierFooterRow">
            <div class="text-center" v-if="localUsername === ''" id="userCashierCreateFooter">
              <div class="col" id="userCashierCreateFooterCol">
                <button
                  v-if="loadingButton === false"
                  class="pl-4 pr-4 btn  btn-success block"
                  @click="createCashierLogin()"
                  id="userCashierSaveButton"
                >
                  {{ $t('save') }}
                </button>
                <button
                  v-else-if="loadingButton === true"
                  class="pl-4 pr-4 btn  btn-success block"
                  disabled
                  id="userCashierSaveButtonDisabled"
                >
                  <CSpinner color="white" size="sm" id="userCashierSaveSpinner"/> {{ $t('save') }}
                </button>
              </div>
            </div>
            <div class="text-center" v-else-if="localUsername !== ''" id="userCashierResetFooter">
              <div class="col" id="userCashierResetFooterCol">
                <button
                  v-if="loadingButton === false"
                  class="pl-4 pr-4 btn btn-outline-info block"
                  @click="resetPassword()"
                  id="userCashierResetButton"
                >
                  {{ $t('resetPassword') }}
                </button>
                <button
                  v-else-if="loadingButton === true"
                  class="pl-4 pr-4 btn btn-outline-info block"
                  id="userCashierResetButtonDisabled"
                >
                  <CSpinner color="white" size="sm" id="userCashierResetSpinner" /> {{ $t('resetPassword') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CModal>
  </div>
  <div v-else id="userCashierAccessDenied">
    <access-data></access-data>
  </div>
</template>

<script>
import local from '@/services/local'
import auth from '@/services/auth'
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    HelpButton
  },
  data() {
    return {
      loadingButton: false,
      loading: '',
      popupModal: false,
      footer: '',
      data: [],
      resetRender: false,
      user: '',
      localpassword: '',
      oldPassword: '',
      currentCasheir: {},
      currentLocalUser: {},
      localUsername: '',
      successAlert: '',
      errorAlert: '',
    }
  },
  created() {
    this.getUserCashier()
  },
  mounted() {
    this.$store.dispatch('getUser')
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'isOwner']),
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
  },
  methods: {
    getUserCashier() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId

      local
        .get('/api/v1.0/' + uid + '/cashier/getall/' + shopObjectId)
        .then((res) => {
          this.data = res.data.data
        })
    },
    manage(cashier) {
      this.popupModal = true
      this.successAlert = ''
      this.errorAlert = ''
      this.currentCasheir = cashier
      this.currentLocalUser = cashier.localUser

      if (this.currentLocalUser != undefined) {
        this.localUsername = this.currentLocalUser.username
      } else {
        this.localUsername = ''
      }
    },
    createCashierLogin() {
      this.loadingButton = true
      let cashier = this.currentCasheir

      let data = {
        user: this.user,
        password: this.localpassword,
        shopObjectId: this.shopObjectId,
        userCashierObjectId: cashier.objectId,
        userCashier: {
          id: cashier.id,
          objectId: cashier.objectId,
          username: this.user,
          name: cashier.username,
        },
      }

      auth
        .post('/auth/v1.0/' + this.uid + '/localuser/create', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            let response = res.data.data
            this.updateCashier(response)
            this.errorAlert = ''
            this.successAlert = 'บันทึกข้อมูลสำเร็จ'
          } else {
            this.successAlert = ''
            this.errorAlert = res.data.error.message
          }
          this.loadingButton = false
        })
    },
    updateCashier(responseLocalUser) {
      this.loadingButton = true
      let data = {
        shopObjectId: this.shopObjectId,
        objectId: responseLocalUser.userCashierObjectId,
        localUser: {
          id: responseLocalUser.id,
          objectId: responseLocalUser.objectId,
          username: responseLocalUser.user,
        },
      }

      local
        .post('/api/v1.0/' + this.uid + '/cashier/update', data)
        .then((res) => {
          this.loadingButton = false
        })
    },
    resetPassword() {
      this.loadingButton = true

      let data = {
        user: this.localUsername,
        password: this.localpassword,
        oldPassword: this.oldPassword,
      }

      auth
        .post('/auth/v1.0/' + this.uid + '/localuser/resetpassword', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            let response = res.data.data
            this.errorAlert = ''
            this.successAlert = 'บันทึกข้อมูลสำเร็จ'
          } else {
            this.successAlert = ''
            this.errorAlert = res.data.error.message
          }
          this.loadingButton = false
        })
    },
  },
}
</script>

<style></style>
